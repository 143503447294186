import {
  CheckCircle,
  HomeWork,
  InfoRounded,
  Person,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  Typography,
  Dialog,
  Tooltip,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { Formik } from "formik";
import FormikErrorFocus from "formik-error-focus";
import { useCallback, useMemo, useState } from "react";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import axios from "../utils/axios";
import Autocomplete from "../components/Autocomplete";
import Checkbox from "../components/Checkbox";
import TextField from "../components/TextField";
import DatePicker from "../components/DatePicker";
import PhoneField from "../components/PhoneField";
import { BACKDROP_COLOR } from "../styles/theme";
import { sleep } from "../utils/helpers";
import { INSTITUTION, PHONE_REG_EXP, COUNTRIES, YEARS } from "../utils/consts";

const useStyles = makeStyles(() => ({
  card: {
    padding: 20,
    background: "#fff",
  },
  icon: {
    marginRight: 12,
  },
  tooltip: {
    padding: 16,
    fontSize: 13,
  },
}));

const cardPadding = { padding: [0, 2, 4] };

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  password: "",
  password_confirmation: "",
  agree: "",
  teacher_request: false,
  institution: undefined,
  secondary_year: null,
  teams: [],
  dob: null,
  ethnicities: [],
  skill_ids: [],
};

const SignupSchema = () =>
  Yup.object().shape({
    first_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    last_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone: Yup.string()
      .matches(PHONE_REG_EXP, "Phone number is not valid")
      .required("Required"),
    password: Yup.string().min(8, "Too Short!").required("Required"),
    password_confirmation: Yup.mixed()
      .oneOf([null, Yup.string()])
      .when("password", {
        is: (value) => value?.length >= 8,
        then: Yup.string()
          .oneOf([Yup.ref("password")], "Passwords must match")
          .required("Confirm Password is required"),
      }),
    agree: Yup.boolean()
      .required("The privacy policy must be accepted.")
      .oneOf([true], "The privacy policy must be accepted."),
    dob: Yup.date()
      .nullable()
      .typeError("Invalid Date")
      .required("Please enter a date of birth")
      .max(
        dayjs().subtract(5, "y").format("YYYY-MM-DD"),
        "You must be over five"
      )
      .min(
        dayjs().subtract(100, "y").format("YYYY-MM-DD"),
        "Please review date of birth"
      ),
    institution: Yup.object()
      .required("Please chose an institution")
      .shape({ id: Yup.number().required("Please chose an institution") })
      .nullable(),
    secondary_year: Yup.object().nullable(),
    teams: Yup.array().min(1, "At least one team is required"),
    teacher_request: Yup.boolean().when(["institution", "secondary_year"], {
      is: (instution, secondaryYear) => {
        if (
          instution?.type === ("secondary" || "intermediate") &&
          !secondaryYear?.value
        ) {
          return true;
        }
        return false;
      },
      then: Yup.boolean()
        .required("Please confirm if you are a teacher")
        .oneOf(
          [true],
          "Please select a school year or confirm that you are a teacher"
        ),
    }),
  });

const headerStyle = {
  fontSize: 24,
  color: "primary.main",
  fontWeight: 700,
  marginRight: 1,
};

const positionRelative = {
  position: "relative",
};

export default function SignUp({ open, onClose }) {
  const classes = useStyles();
  const history = useHistory();

  const [institutions, setInstitutions] = useState([]);
  const [errors, setErrors] = useState([]);
  const [creating, setCreating] = useState(false);
  const [viewTooltip, setViewTooltip] = useState(false);
  const [createdAccount, setCreated] = useState(false);

  const handleToogleTooltip = useCallback((event) => {
    event.preventDefault();
    setViewTooltip((prev) => !prev);
  }, []);

  const handleSubmitFormik = useCallback(
    async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        setErrors([]);
        const obj = {
          ...values,
          volunteer_details: {
            dob: dayjs(values.dob).toISOString(),
            institution_id: values.institution.id,
            has_graduated: !values.secondary_year?.value,
            secondary_year: values.secondary_year?.value ?? null,
          },
          team_ids: values.teams.map((t) => t.id),
        };
        await axios.post("auth/register", obj);
        setCreating(true);
        await sleep(3000);
        setCreated(true);
        await sleep(5000);
        history.push("/");
        setCreating(false);
        setCreated(false);
        onClose();
      } catch (error) {
        setErrors(
          Object.values(
            error.errors || { empty: "Unknown error please contact SVA" }
          )
        );
      } finally {
        setSubmitting(false);
      }
    },
    [history]
  );

  const handleInstitutionSearchChange = useCallback(async (text) => {
    const response = await axios.get(
      `institutions?include=teams&filter[name~]=${text}`
    );
    setInstitutions(response?.data?.data);
  }, []);

  const creatingMemo = useMemo(
    () =>
      !createdAccount ? (
        <Backdrop
          sx={{
            backgroundColor: "rgba(240, 242, 250, 0.93)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography
              align="center"
              sx={{
                color: "primary.main",
                fontSize: 32,
                fontWeight: 800,
                lineHeight: "44px",
                maxWidth: 440,
              }}
            >
              Hold on tight.. we are setting up your account!
            </Typography>
            <CircularProgress sx={{ marginTop: 3 }} color="inherit" />
          </Box>
        </Backdrop>
      ) : (
        <Backdrop
          sx={{
            backgroundColor: "rgba(240, 242, 250, 0.93)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography
              align="center"
              sx={{
                color: "primary.main",
                fontSize: 32,
                fontWeight: 800,
                lineHeight: "44px",
                maxWidth: 440,
              }}
            >
              Account created!
            </Typography>
            <Typography
              align="center"
              sx={{
                color: "primary.main",
                fontSize: 24,
                fontWeight: 700,
                lineHeight: "44px",
                maxWidth: 440,
              }}
            >
              Please verify your email account to sign in.
            </Typography>
            <CheckCircle
              sx={{ fontSize: 48, marginTop: 3, color: "#00A59B" }}
              color="inherit"
            />
          </Box>
        </Backdrop>
      ),
    [createdAccount]
  );

  if (!open) {
    return null;
  }

  return !creating ? (
    <Dialog
      BackdropComponent={styled(Backdrop, {
        name: "MuiModal",
        slot: "Backdrop",
        overridesResolver: (props, styles) => styles.backdrop,
      })({ zIndex: -1, backgroundColor: BACKDROP_COLOR })}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      scroll="body"
      transitionDuration={{ appear: 0 }}
    >
      <Card className={classes.card} sx={cardPadding}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={handleSubmitFormik}
          >
            {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box flexDirection="row" display="flex" alignItems="center">
                      <Person sx={headerStyle} />
                      <Typography sx={headerStyle}>Personal Details</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField required name="first_name" label="First name" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField required name="last_name" label="Last name" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      name="email"
                      label="Email"
                      autoComplete="username"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={positionRelative}>
                    <PhoneField
                      required
                      name="phone"
                      label="Phone Number"
                      countryOptions={COUNTRIES}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker required label="Date of Birth" name="dob" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      name="password"
                      label="Password"
                      type="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      name="password_confirmation"
                      label="Confirm Password"
                      type="password"
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box flexDirection="row" display="flex" alignItems="center">
                      <HomeWork sx={headerStyle} />
                      <Typography sx={headerStyle}>Institution</Typography>
                    </Box>
                    <Typography variant="caption">
                      If you are not a part of any {INSTITUTION}, please sign up
                      to{" "}
                      <Typography variant="caption" sx={{ fontWeight: 600 }}>
                        SVA General
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={institutions}
                      handleInputChange={handleInstitutionSearchChange}
                      name="institution"
                      label={`Choose your ${INSTITUTION}`}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {values?.institution && (
                      <Autocomplete
                        multiple
                        options={values?.institution?.teams}
                        name="teams"
                        label="Teams"
                        required
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    hidden={
                      values?.institution?.type !==
                      ("secondary" || "intermediate")
                    }
                  >
                    <Autocomplete
                      options={YEARS}
                      label="Year"
                      name="secondary_year"
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Checkbox
                      name="teacher_request"
                      label={
                        <Box display="flex" flexDirection="row">
                          <Typography className={classes.icon}>
                            I am a teacher / facilitator
                          </Typography>
                          <Tooltip
                            open={viewTooltip}
                            onOpen={handleToogleTooltip}
                            onClose={handleToogleTooltip}
                            title="An email with your request will be sent to SVA, we will review your request ASAP. If we will need more
                              information we will contact you. Once approved we will let you know."
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <InfoRounded
                              fontSize="small"
                              onClick={handleToogleTooltip}
                            />
                          </Tooltip>
                        </Box>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      name="subscribe"
                      label="Subscribe to receive our newsletter and updates"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      name="agree"
                      required
                      label={
                        <Typography>
                          I agree to{" "}
                          <Link
                            target="_blank"
                            rel="noopener"
                            href={process.env.REACT_APP_PRIVACY_POLICY}
                          >
                            Privacy Policy
                          </Link>
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {errors.map((text) => (
                      <Box key={text} mt={2}>
                        <Alert severity="error">{text}</Alert>
                      </Box>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      loading={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      <InsertEmoticonIcon className={classes.icon} />
                      SIGN ME UP!
                    </LoadingButton>
                  </Grid>
                </Grid>
                <FormikErrorFocus
                  offset={0}
                  align="top"
                  focusDelay={200}
                  ease="linear"
                  duration={1000}
                />
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Dialog>
  ) : (
    creatingMemo
  );
}

SignUp.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
